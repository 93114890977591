import React, { useEffect } from 'react';
import { Like } from 'react-facebook';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import getLanguage from '../utils/getLanguage';
import mapEngine from '../components/map';
import '../css/map.less';

export default function About() {
  const language = getLanguage();

  useEffect(() => {
    mapEngine.init('worldmap', 660, 470, language);
  }, [language]);

  return (
    <Layout page="vis">
      <SEO title="Extras*" />
      <div className="textContent">
        <h2>Extras</h2>
        {language === 'en' ? (
          <>
            <p>
              I made this page purely just for fun. Since visualization is what I do for a living,
              my wedding site definitely deserves to have one.
            </p>
            <p>
              Just in case you don't know where are San Francisco, DC and Bangkok, this map can
              quickly show you. In short, they are on the opposite site of the globe.
            </p>
            <p>
              The solid gray lines represent the number of time Krist flew home since he came to
              study in the U.S. (8 times in 5 years, approximately 160,000 miles -- in other words,
              roughly six time around the world) The dashed pink line show the link from my current
              location (San Francisco) to my fiance's location (Bangkok) and also means the trip
              that I am going home to get married.
            </p>
            <p>
              Under each city, there is a clock that show local time. The difficulty of long
              distance relationship is not only that you are not together, but you are also on
              different time zones. You may be sleeping while your boyfriend/girlfriend is working,
              and vice versa.
            </p>
            <p>
              I thought it would be much more fun to make this interactive, so I did. You can place
              the mouse over U.S. or Thailand on the map and see the "missing you" bar on the top
              changing!
            </p>
            <p>
              P.S. The route I usually fly home is via Japan or South Korea, so the lines are not
              really showing the route, but for the sake of simplicity and because I am lazy, I will
              just leave it like this.
            </p>
          </>
        ) : (
          <>
            <p>
              หน้านี้แถม visualization ที่ทำมาเล่นๆ ไหนๆก็ทำมาหากินด้วยการทำ visualization
              เป็นอาชีพแล้ว เว็บแต่งงานก็สมควรจะใส่ซักหน่อย เผื่อคนไม่รู้ว่า DC หรือ San Fran
              อยู่ตรงไหน ลองดูจากแผนที่ได้ครับ สรุปได้สั้นๆว่ามันอยู่คนละซีกโลกกับประเทศไทย ไกลโฮก
            </p>
            <p>
              เส้นทึบสีเทาแสดงจำนวนครั้งที่หมูบินกลับบ้านตั้งแต่มาเรียน (5 ปีบินไปกลับ 8 รอบ
              ระยะทางก็ ~160,000 ไมล์ หรือบินรอบโลกไปหกรอบกว่าๆ) ส่วนเส้นประสีชมพูลากเชื่อม San
              Francisco (ที่หมูอยู่ตอนนี้) และกรุงเทพ (ที่เบญอยู่ตอนนี้)
              และยังหมายความถึงเที่ยวบินรอบนี้ที่จะบินกลับไปแต่งงานด้วยครับ ^_^
            </p>
            <p>
              ใต้ชื่อเมืองมีนาฬิกาบอกเวลาท้องถิ่น นอกจากตัวจะอยู่คนละซีกโลกแล้ว
              ความมึนงงในชีวิตอีกอย่างคือเวลากลับหัวกลับหาง คนนึงนอนอีกคนเพิ่งตื่น
            </p>
            <p>
              ไหนๆจะทำแล้วก็ทำให้คนดูเล่นได้ด้วย ลองเอาเมาส์ชี้ที่อเมริกาหรือไทยบนแผนที่ดูครับ
              แล้วดูแถบความคิดถึงข้างบนขยับไปมา (ได้ไอเดียมาจากพวกแถบพลังในพวกเกมต่อสู้)
            </p>
            <p>
              ป.ล. ความจริงเส้นทางบินมันต้องไปทางญี่ปุ่น แต่แบบนั้นมันจะวาดบนแผนที่ให้เข้าใจลำบาก
              ก็เลย...ช่างมันเถอะ
            </p>
          </>
        )}
      </div>
      <p>&nbsp;</p>
      <div id="worldmap" style={{ width: 660, height: 470 }} />
      <p>&nbsp;</p>
      <a
        href="https://twitter.com/share"
        className="twitter-share-button"
        data-text="The playful wedding visualization"
        data-via="kristw"
        data-hashtags="benandmuu"
      >
        Tweet
      </a>
      &nbsp;
      <Like
        href="http://benandmuu.yellowpigz.com"
        width="450"
        layout="button_count"
        action="like"
        size="small"
        showFaces={false}
        share={false}
        font="arial"
      />
    </Layout>
  );
}
