Date.prototype.stdTimezoneOffset = function () {
  const jan = new Date(this.getFullYear(), 0, 1);
  const jul = new Date(this.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

Date.prototype.dst = function () {
  return this.getTimezoneOffset() < this.stdTimezoneOffset();
};

function quadraticBezier(p0, p1, p2) {
  return function (t) {
    return (1 - t) ** 2 * p0 + 2 * (1 - t) * t * p1 + t * t * p2;
  };
}

const mapEngine = (function () {
  let $container;
  let vis;
  let lang = 'th';

  function scoreText(percent) {
    if (percent >= 0.9) {
      return lang == 'th' ? 'รักเขาข้างเดียว T_T' : 'Heartbroken T_T';
    }
    if (percent >= 0.8) {
      return lang == 'th' ? 'คิดถึงเธอแทบใจจะขาด...' : "I'm dying missing you...";
    }
    if (percent >= 0.7) {
      return lang == 'th' ? 'คิดถึงม้ากมาก' : 'Miss you very much';
    }
    if (percent >= 0.6) {
      return lang == 'th' ? 'คิดถึงมาก' : 'Miss you much';
    }
    if (percent >= 0.5) {
      return lang == 'th' ? 'คิดถึง' : 'Miss you';
    }
    if (percent >= 0.4) {
      return lang == 'th' ? 'คิดถึงเหมือนกัน' : 'Miss you too';
    }
    if (percent >= 0.3) {
      return lang == 'th' ? 'คิดถึงก็ได้' : 'Miss you a little';
    }
    if (percent >= 0.2) {
      return lang == 'th' ? 'อืมม' : 'Ummm... okay';
    }
    if (percent >= 0.1) {
      return lang == 'th' ? 'เอ่อ' : 'WTF!';
    }

    return '...';
  }

  function init(id, w, h, langIn) {
    if (langIn) {
      lang = langIn;
    }

    $container = $(`#${id}`);
    $container.html('');
    vis = d3.select(`#${id}`).append('svg').attr('width', w).attr('height', h);

    const projection = d3.geo
      .mercator()
      .translate([w / 2 + 20, h / 2])
      .scale(w + 240);
    // .translate([-61, 56]).scale(500);
    const path = d3.geo.path().projection(projection);

    const map = vis.append('g').attr('transform', 'translate(0,100)');

    const gMapPieces = map.append('g');
    const gCurve = map.append('g');
    const gBubble = map.append('g');

    const score = { us: 50, th: 50, percentUS: 0.5, percentTH: 0.5 };

    const gScore = vis.append('g');

    gScore
      .append('rect')
      .classed('scorebg', true)
      .attr('x', 5)
      .attr('y', 5)
      .attr('width', w - 10)
      .attr('height', 16);
    const rect1 = gScore
      .append('rect')
      .attr('id', 'score1')
      .classed('score1', true)
      .attr('x', 7)
      .attr('y', 7)
      .attr('width', (w - 14) * score.percentUS)
      .attr('height', 12);
    const rect2 = gScore
      .append('rect')
      .attr('id', 'score2')
      .classed('score2', true)
      .attr('x', 7 + (w - 14) * score.percentUS)
      .attr('y', 7)
      .attr('width', (w - 14) * score.percentTH)
      .attr('height', 12);
    const text1 = gScore
      .append('text')
      .classed('scoreText', true)
      .attr('x', 7 + ((w - 14) * score.percentUS) / 2)
      .attr('y', 40)
      .text(scoreText(score.percentUS));
    const text2 = gScore
      .append('text')
      .classed('scoreText', true)
      .attr('x', 7 + (w - 14) * score.percentUS + ((w - 14) * score.percentTH) / 2)
      .attr('y', 40)
      .text(scoreText(score.percentTH));

    d3.json('data/world-countries.json', function (collection) {
      // Draw map
      gMapPieces
        .selectAll('path')
        .data(collection.features)
        .enter()
        .append('svg:path')
        .attr('class', function (d) {
          if (d.id == 'TH' || d.id == 'US') {
            return 'special';
          }
          return '';
        })
        .attr('id', function (d) {
          return d.id;
        })
        .attr('d', path);

      function addBubble(startXY, endXY, country, anotherCountry) {
        return setInterval(function () {
          score[country]++;
          score[country] = Math.min(100, score[country]);
          score[anotherCountry] = 100 - score[country];

          const sum = score.us + score.th + 0;
          score.percentUS = score.us / sum;
          score.percentTH = score.th / sum;

          rect1
            .transition()
            .delay(500)
            .duration(500)
            .attr('width', (w - 14) * score.percentUS);
          rect2
            .transition()
            .delay(500)
            .duration(500)
            .attr('x', 7 + (w - 14) * score.percentUS)
            .attr('width', (w - 14) * score.percentTH);
          text1
            .transition()
            .delay(500)
            .duration(500)
            .attr('x', 7 + ((w - 14) * score.percentUS) / 2)
            .text(scoreText(score.percentUS));
          text2
            .transition()
            .delay(500)
            .duration(500)
            .attr('x', 7 + (w - 14) * score.percentUS + ((w - 14) * score.percentTH) / 2)
            .text(scoreText(score.percentTH));

          gBubble
            .append('circle')
            .classed('bubble', true)
            .attr('r', 2)
            .attr('cx', startXY[0])
            .attr('cy', startXY[1])
            .transition()
            .duration(3000)
            .attr('cx', endXY[0])
            .attrTween('cx', function (d, i, a) {
              const p0 = startXY[0];
              const p2 = endXY[0];
              const p1 = (p0 + p2) / 3;
              return quadraticBezier(p0, p1, p2);
            })
            .attr('cy', endXY[1])
            .attrTween('cy', function (d, i, a) {
              const p0 = startXY[1];
              const p2 = endXY[1];
              const p1 = -200;
              return quadraticBezier(p0, p1, p2);
            })
            .remove();
        }, 200);
      }

      let usInterval;
      gMapPieces
        .select('path#US')
        .on('mouseover', function (d) {
          usInterval = addBubble(beaconData[0].xy, beaconData[2].xy, 'us', 'th');
          d3.select(this).classed('hover', true);
          gCurve.select('path.sfLine').transition().attr('opacity', 0);
        })
        .on('mouseout', function (d) {
          clearInterval(usInterval);
          d3.select(this).classed('hover', false);
          gCurve.select('path.sfLine').transition().delay(2000).attr('opacity', 1);
        });

      let thInterval;
      gMapPieces
        .select('path#TH')
        .on('mouseover', function (d) {
          thInterval = addBubble(beaconData[2].xy, beaconData[0].xy, 'th', 'us');
          d3.select(this).classed('hover', true);
          gCurve.select('path.sfLine').transition().attr('opacity', 0);
        })
        .on('mouseout', function (d) {
          clearInterval(thInterval);
          d3.select(this).classed('hover', false);
          gCurve.select('path.sfLine').transition().delay(2000).attr('opacity', 1);
        });

      const isDST = Date.today().dst();
      var beaconData = [
        { name: 'San Francisco', loc: [-122.417115, 37.776905], timeOffset: isDST ? -7 : -8 },
        { name: 'Washington, DC', loc: [-76.938483, 38.989169], timeOffset: isDST ? -4 : -5 },
        { name: 'Bangkok', loc: [100.494763, 13.751474], timeOffset: 7 },
      ];
      beaconData.forEach(function (d) {
        d.xy = projection(d.loc);
      });

      // Draw curve lines
      const travelCount = [];
      for (let i = 0; i < 8; i++) {
        travelCount.push(i);
      }

      gCurve
        .selectAll('path.travelLine')
        .data(travelCount)
        .enter()
        .append('path')
        .classed('travelLine', true)
        .attr('opacity', 0)
        .attr('d', function (d, i) {
          const xy1 = beaconData[1].xy;
          const xy2 = beaconData[2].xy;
          return `M ${xy1.join(
            ' ',
          )} Q ${(xy1[0] + xy2[0]) / 2} ${(100 * (8 - i)) / 7} ${xy2.join(' ')}`;
        })
        .transition()
        .delay(function (d, i) {
          return i * 100;
        })
        .duration(2000)
        .attr('opacity', 1);

      gCurve
        .append('path')
        .classed('sfLine', true)
        .attr('opacity', 0)
        .attr('d', function (d, i) {
          const xy1 = beaconData[0].xy;
          const xy2 = beaconData[2].xy;
          return `M ${xy1.join(' ')} Q ${(xy1[0] + xy2[0]) / 3} ${-200} ${xy2.join(' ')}`;
        })
        .transition()
        .delay(800)
        .duration(2000)
        .attr('opacity', 1);

      // map.append("text")
      //   .attr("x", 285)
      //   .attr("y", 200)
      //   .text("8 trips in 5 years")
      // map.append("text")
      //   .attr("x", 285)
      //   .attr("y", 220)
      //   .text("~80,000 miles")

      const gBeacon = map
        .selectAll('g.beacon')
        .data(beaconData)
        .enter()
        .append('g')
        .attr('transform', function (d) {
          const xy = projection(d.loc);
          return `translate(${xy[0]},${xy[1]})`;
        });

      // Draw label lines
      gBeacon.append('polyline').classed('label', true).attr('points', '0,0 10,10 10,40');

      gBeacon.append('circle').classed('beacon', true).attr('r', 4);

      gBeacon
        .append('text')
        .classed('beacon', true)
        .attr('x', 10)
        .attr('y', 60)
        .text(function (d) {
          return d.name;
        });

      function time(d) {
        const date = new Date();
        const localTime = date.getTime();
        const localOffset = date.getTimezoneOffset() * 60000;
        const utcTime = localTime + localOffset;
        const destinationTime = utcTime + d.timeOffset * 3600000;
        const destinationDate = new Date(destinationTime);
        return destinationDate.toString('hh:mm:ss tt');
      }

      const timeText = gBeacon
        .append('text')
        .classed('time', true)
        .attr('x', 10)
        .attr('y', 80)
        .text(time);

      setInterval(function () {
        timeText.text(time);
      }, 1000);
    });
  }

  return {
    init,
  };
})();

export default mapEngine;
